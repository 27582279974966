<template>
	<div class="sms-modal">
		<div class="w-full">
			<img
				src="@/assets/icons/sms.svg"
				class="pl-10 mx-auto"
				height="170"
				width="140"
				alt="sms icon"
			>
		</div>
		<h2 class="text-2xl font-semibold">
			Verify your phone number
		</h2>
		<p class="py-2">
			Must be a US / Canadian number. <br>Data rates may apply.
		</p>
		<p class="pb-2">
			<strong>
				Verify by:
			</strong>
		</p>
		<div class="flex justify-center w-full">
			<span class="relative z-0 inline-flex w-4/5 mx-auto text-center">
				<button
					ref="sms"
					type="button"
					:class="smsToggle ? 'bg-green-500 text-white border-2 border-green-300' : 'bg-white text-green'"
					class="relative inline-flex items-center w-1/2 py-2 -ml-px text-base font-semibold text-center border border-green-500 shadow-sm rounded-l-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-green-300"
					@click="setMethod('SMS')"
				>
					<span class="mx-auto text-center">
						SMS Message
					</span>
				</button>
				<button
					ref="voice"
					type="button"
					:class="voiceToggle ? 'bg-green-500 text-white border-2 border-green-300' : 'bg-white text-green'"
					class="relative inline-flex items-center w-1/2 py-2 -ml-px text-base font-semibold text-center border border-green-500 shadow-sm rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-green-300"
					@click="setMethod('Voice')"
				>
					<span class="mx-auto text-center">
						Voice Call
					</span>
				</button>
			</span>
		</div>

		<div class="mt-2 mb-2 phone-input">
			<input
				ref="digit1"
				v-model="digit[0]"
				step="1"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit1', 0)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit2"
				v-model="digit[1]"
				step="2"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit2', 1)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit3"
				v-model="digit[2]"
				step="3"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit3', 2)"
				@keyup="checkDelete($event)"
			>
			<span class="split">
				-
			</span>
			<input
				ref="digit4"
				v-model="digit[3]"
				step="4"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit4', 3)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit5"
				v-model="digit[4]"
				step="5"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit5', 4)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit6"
				v-model="digit[5]"
				step="6"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit6', 5)"
				@keyup="checkDelete($event)"
			>
			<span class="split">
				-
			</span>
			<input
				ref="digit7"
				v-model="digit[6]"
				step="7"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit7', 6)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit8"
				v-model="digit[7]"
				step="8"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit8', 7)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit9"
				v-model="digit[8]"
				step="9"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit9', 8)"
				@keyup="checkDelete($event)"
			>
			<input
				ref="digit10"
				v-model="digit[9]"
				step="10"
				type="number"
				max="1"
				value=""
				@keydown="setFocusToFalse()"
				@focus="selectInput($event, 'digit10', 9)"
				@keyup="checkDelete($event)"
			>
		</div>
		<button
			ref="submit"
			class="w-full px-3 py-2 font-semibold text-white rounded-md focus:ring focus:ring-green-300"
			:class="submitDisabled ? 'bg-gray-300 border-gray-300' : 'bg-green-500 border-green-500'"
			:disabled="submitDisabled"
			@click="sendCode"
		>
			Send Code
		</button>
	</div>
</template>

<script async>

export default {
	props: {
		focusOnInput: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'enter-number-data', 'final-step', 'focus-to-false' ],
	data() {
		return {
			digit: [
				'', // digit1
				'', // digit2
				'', // digit3
				'', // digit4
				'', // digit5
				'', // digit6
				'', // digit7
				'', // digit8
				'', // digit9
				'' // digit10
			],
			step: 0,
			shown: false,
			elementId: '',
			digitId: '',
			nextDigit: '',
			nextDigitId: '',
			previousDigit: '',
			previousDigitId: '',
			smsToggle: true,
			voiceToggle: false,
			verificationMethod: 'SMS',
			submitDisabled: false
		}
	},
	computed: {
		formattedPhoneNumber() {
			const formattedNum = this.digit[0] + this.digit[1] + this.digit[2] + '-' + this.digit[3] + this.digit[4] + this.digit[5] + '-' + this.digit[6] + this.digit[7] + this.digit[8] + this.digit[9]
			return formattedNum
		},
		currentInputValue() {
			return this.digit[this.digitId]
		}
	},
	watch: {
		digit: {
			handler: function() { // if this input value is not an empty string, use substring to trim any number passed the first number entered.
				if (this.digit[this.digitId] !== '') {
					const trimmedInput = this.digit[this.digitId].substring(0, 1)
					this.digit[this.digitId] = trimmedInput
					if (this.elementId !== 'digit10') {
						this.$refs[this.nextDigitId].focus()
					} else {
						this.$refs.submit.focus()
					}
				}
				this.sumbitReady()
			},
			deep: true
		},
		focusOnInput: {
			handler: function() {
				if (this.focusOnInput) {
					this.$refs.submit.focus()

					for (let index = 0; index < 10; index++) {
						if (this.digit[index] == undefined || this.digit[index] == '') {
							const newDigitId = 'digit' + (index + 1).toString()
							this.$refs[newDigitId].focus()
							break
						} else if (this.digit[index] !== undefined || this.digit[index] !== '') {
							continue
						}
					}
				}
			}
		}
	},
	mounted () {
		this.sumbitReady()
	},
	methods: {
		checkDelete(event) {
			if (this.currentInputValue == '') { // allows backspace and delete to move backwards though inputs
				if (event.key == 'Delete' || event.key == 'Backspace') {
					if (this.elementId !== 'digit1') {
						this.$refs[this.previousDigitId].focus()
					}
				}
			}
		},
		selectInput(event, inputId, arrayPosition) {
			event.target.select()
			this.setCurrentInput(inputId, arrayPosition)
		},
		setCurrentInput(inputId, arrayPosition) {
			this.elementId = inputId // this stores the active input's id so that we can shift focus on keyup based on what input we are using.
			this.digitId = arrayPosition // this stores the active input's position in the digit array so that we can reference it in the watcher to trim excess characters.
			this.nextDigit = this.digitId + 2
			this.previousDigit = this.digitId
			this.nextDigitId = 'digit' + this.nextDigit.toString()
			this.previousDigitId = 'digit' + this.previousDigit.toString()
		},
		setFocusToFalse() {
			this.$emit('focus-to-false')
		},
		sumbitReady() { // disable submit button if all inputs are not populated
			for (let index = 0; index < 10; index++) {
				this.submitDisabled = false
				if (this.digit[index] == undefined || this.digit[index] == '') {
					this.submitDisabled = true
					break
				} else if (this.digit[index] !== undefined || this.digit[index] !== '') {
					continue
				}
			}
		},
		setMethod(method) {
			this.verificationMethod = method
			if (this.verificationMethod == 'SMS') {
				this.smsToggle = true
				this.voiceToggle = false
			} else {
				this.smsToggle = false
				this.voiceToggle = true
			}
		},
		sendCode: async function () {
			const error = 'Unknown error'
			const postData = {
				type: 'Verification',
				func_name: 'sendVerificationCode',
				data: {
					phone: this.digit,
					preferred_method: this.verificationMethod
				}
			}

			try {
				const returnData = await this.$store.dispatch('apiCall', postData)
				if (returnData.data.success === true) {
					if (returnData.data?.auth?.verified_phone || returnData.data?.results?.skip_verification) {
						this.$emit('final-step', 2)
					} else {
						this.$emit('enter-number-data', { phone: this.formattedPhoneNumber, method: this.verificationMethod, phoneNumberArray: this.digit, step: 1 })
					}
				} else {
					throw error
				}
			} catch (error) {
				//
			}
		}
	}
}
</script>
